
import { Component, Vue } from 'vue-property-decorator';
import SvgIcon from '@/components/utilities/SvgIcon.vue';

@Component({
    components: {
        SvgIcon,
    },
})
export default class NotFound extends Vue {
    link: { name: string } = { name: '' };

    async mounted()
    {
        this.link.name = this.$route.params.URL
    }

    goToLink() {
        window.location.href = this.$route.params.URL
    }

}
